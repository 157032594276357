<template>
  <div class="refunds-profile">
      <draggable-dynamic-table ref="refundsProfileRulesList"
                               v-if="data.length > 0"
                               :options="options"
                               :columns="columnsLabel"
                               :data="data"
                               :in-modal="true"/>
  </div>
</template>

<script>
  import { getRefund } from "../../../../http/requests/refundRules";
  import {addComma, hideLoading, showLoading} from "../../../../assets/js/functions";

  export default {
    name: "refundsProfile",
    props: {
      refundId: 0
    },
    data () {
      return {
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          id: 'refundsProfileRulesList',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'refund',
            i18n: 'refunds.profile.table.header.refund',
            width: 'calc(100% / 3)',
            minWidth: 100,
          },
          {
            field: 'time',
            i18n: 'refunds.profile.table.header.time',
            align: 'center',
            width: 'calc((100% / 3) * 2)',
            minWidth: 100,
          },
        ],
        refunds: {
          id: 0,
          name: '',
          price: '',
          character: ''
        }
      }
    },
    computed: {
    },
    created () {
      this.getRefund()
      showLoading()
    },
    methods: {
      getRefund () {
        getRefund(this.refundId).then(response => {
          const refunds = response.data.data

          this.refunds.id = refunds.id
          const orderedHours = refunds.schedules.map(e => e.hour).sort((a,b) => a-b)
          if (refunds.schedules.length > 0) {
            refunds.schedules.forEach(item => {
              const hourIndex = orderedHours.indexOf(item.hour)
              this.data.push({
                id: item.id || 0,
                rowNumber: this.data.length + 1,
                hour: item.hour,
                time: orderedHours.length > 1 ? (item.hour === 0 ? this.$t('refunds.timeLabels.from', {time: orderedHours[1]}) : hourIndex < orderedHours.length - 1 ? this.$t('refunds.timeLabels.fromTo', {from: orderedHours[hourIndex + 1], to: orderedHours[hourIndex]}) : this.$t('refunds.timeLabels.to', {time: orderedHours[hourIndex]})) : this.$t('refunds.timeLabels.all'),
                refund: `%${item.percent}`
              })
            })
            this.data.sort((a,b) => {return a.hour - b.hour})
          }
          hideLoading()
        })
          .catch(() => {
            hideLoading()
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .refunds-profile {
    height: 100%;
  }
</style>
