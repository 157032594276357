<template>
  <div class="useral-edit-refunds">
    <vs-divider class="md:hidden"/>

    <div class="view-side mt-3 sm:mt-0">
      <template v-for="i in 5">
        <div class="input-field flex">
          <label class="input-label w:1/2">
            {{ refundsHoursInputs[i - 1].time }} :
          </label>
          <div class="input-percent">
            <span class="percent-icon">
              %
            </span>
            <custom-validate-input class="number-style"
                                   :key="i"
                                   invalid-text=" "
                                   :regex="percentRegex"
                                   :not-valid="handleInputValid(i - 1)"
                                   v-model="refundsHoursInputs[i - 1].refund"
                                   @input="handleInputValue(i - 1, $event)"/>
          </div>
          <div v-if="!refundsHoursInputs[i - 1].refund.isValid" class="in-valid">
            {{ handleInputValid(i - 1) ? $t('refunds.validators.value') : ''}}
            {{ !handleInputValid(i - 1) ? $t('refunds.validators.percentRange') : ''}}
          </div>
        </div>

      </template>
    </div>

    <vs-button v-show="false"
               id="editRefundBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomValidateInput from '@/components/customInput/customValidateInput'
  import CustomDialog from '@/components/customDialog/customDialog'
  import {checkUserPermissions} from '@/assets/js/functions'
  import CustomSelect from '@/components/customSelect/customSelect'
  import CustomNumberInput from "../../../../components/customInput/customNumberInput";
  import {editRefund, getRefund} from "../../../../http/requests/refundRules";

  export default {
    name: 'EditRefund',
    components: {
      CustomNumberInput,
      CustomSelect,
      CustomDialog,
      CustomValidateInput
    },
    props: {
      refundId: 0
    },
    data() {
      return {
        percentRegex: this.$validator('regex.refund.percent'),
        nameRegex: this.$validator('regex.refund.name'),
        limits: [
          {
            id: 0,
            label: this.$t('refunds.timeLabels.all')
          },
          {
            id: 1,
            label: this.$t('refunds.timeLabels.all')
          }
        ],
        saleAddon: {
          name: {
            value: '',
            isValid: false
          },
          maxSelect: {
            value: '',
            isValid: false
          },
          character: {
            id: null
          }
        },
        refunds: {
          id: 0,
          limit: {
            id: -1
          },
          name: '',
          price: '',
          character: ''
        },
        hoursList: [],
        refundsHoursValues: [
          {
            label: this.$t('refunds.timeLabels.all'),
            value: {
              id: 0,
              initialId: 0,
              name: this.$t('refunds.timeLabels.all'),
              value: 0
            }
          },
          {
            label: this.$t('refunds.timeLabels.fromTo', {from: '24', to: '12'}),
            value: {
              id: 2,
              initialId: 0,
              name: this.$t('refunds.timeLabels.fromTo', {from: '24', to: '12'}),
              value: 12
            }
          },
          {
            label: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '24'}),
            value: {
              id: 3,
              initialId: 0,
              name: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '24'}),
              value: 24
            }
          },
          {
            label: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '12'}),
            value: {
              id: 4,
              initialId: 0,
              name: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '12'}),
              value: 48
            }
          },
          {
            label: this.$t('refunds.timeLabels.to', {time: '72'}),
            value: {
              id: 5,
              initialId: 0,
              name: this.$t('refunds.timeLabels.to', {time: '72'}),
              value: 72
            }
          },
        ],
        refundsHoursInputs: [
          {
            id: 0,
            initialId: 0,
            time: this.$t('refunds.timeLabels.from', {time: '12'}),
            hour: 0,
            refund: {
              value: '',
              isValid: true
            }
          },
          {
            id: 1,
            initialId: 0,
            time: this.$t('refunds.timeLabels.fromTo', {from: '24', to: '12'}),
            hour: 12,
            refund: {
              value: '',
              isValid: true
            }
          },
          {
            id: 2,
            initialId: 0,
            time: this.$t('refunds.timeLabels.fromTo', {from: '48', to: '24'}),
            hour: 24,
            refund: {
              value: '',
              isValid: true
            }
          },
          {
            id: 3,
            initialId: 0,
            time: this.$t('refunds.timeLabels.fromTo', {from: '72', to: '48'}),
            hour: 48,
            refund: {
              value: '',
              isValid: true
            }
          },
          {
            id: 4,
            initialId: 0,
            time: this.$t('refunds.timeLabels.to', {time: '72'}),
            hour: 72,
            refund: {
              value: '',
              isValid: true
            }
          },
        ],
      }
    },
    created() {
      this.getRefund()
    },
    methods: {
      getRefund () {
        getRefund(this.refundId).then(response => {
          const refunds = response.data.data

          this.refunds.id = refunds.id
          const orderedHours = refunds.schedules.map(e => e.hour).sort((a,b) => a - b)
          const orderSchedules = refunds.schedules.sort((a,b) => a.hour - b.hour)
          if (refunds.schedules.length > 0) {
            if (refunds.schedules.length > 1) {
              this.refunds.limit.id = 1
            } else {
              this.refunds.limit.id = 0
            }
            orderSchedules.forEach(item => {
              const hourIndex = this.refundsHoursValues.map(e => e.value.value).indexOf(item.hour)
              const hourInputIndex = this.refundsHoursInputs.map(e => e.hour).indexOf(item.hour)
              this.hoursList.push({
                id: this.refundsHoursValues[hourIndex].value.id || 0,
                initialId: item.id,
                hour: item.hour || 0,
                refund: {
                  value: `${item.percent}`,
                  isValid: true
                }
              })
              setTimeout(() => {
                this.refundsHoursInputs[hourInputIndex].refund.value = `${item.percent}`
                this.refundsHoursInputs[hourInputIndex].initialId = item.id
                this.handleInputValue(hourInputIndex, this.refundsHoursInputs[hourInputIndex].refund)
              }, 50)

              if (hourIndex > -1) {
                this.refundsHoursValues.splice(hourIndex, 1)
              }
            })
          }
        })
      },
      handleInputValue (index, val) {
        for (let i = index; i < 5; i++) {
          this.refundsHoursInputs[i].refund.value = val.value
        }
        // this.$forceUpdate()
      },
      handleInputValid(index) {
        if (index > 0) {
          let minimumCount = 0
          for (let i = 0; i < index; i++) {
            if (parseInt(this.refundsHoursInputs[index].refund.value) < parseInt(this.refundsHoursInputs[i].refund.value)) {
              minimumCount++
            }
          }
          if (minimumCount > 0) {
            return true
          }
          return parseInt(this.refundsHoursInputs[index].refund.value) < parseInt(this.refundsHoursInputs[index - 1].refund.value)
        } else return false
      },
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      sendData() {
        let errorNum = 0
        let schedules = []
        this.refundsHoursInputs.forEach(item => {
          if (item.refund.isValid) {
            schedules.push({
              id: item.initialId > 0 ? item.initialId : null,
              hour: item.hour,
              percent: parseInt(item.refund.value)
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('refunds.notifications.wrongValue', {name: item.time}),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'danger'
            })
            errorNum++
            return false
          }
        })

        const refundHours = {
          schedules: schedules
        }


        if (errorNum === 0) {
          editRefund(this.refundId, refundHours).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('refunds.notifications.edit.success'),
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400,
              color: 'success'
            })
            this.$emit('edited')
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'name': '',
              }

              switch (error.response.status) {
                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('refunds.notifications.edit.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../assets/scss/vuexy/variables";
  .useral-edit-refunds {
    width: 100%;
    height: calc(100% - 10px);
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }

      ul {

        li {
          position: relative;
          border: 1px solid #cecece;
          border-radius: .5rem;
          margin-bottom: 10px;
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
          transition: all 0.3s ease;

          .icon-chevron-left {
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            right: 6px;
            margin: auto;
          }

          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      border-radius: .5rem;
      overflow-y: auto;


      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        height: calc(100% - 230px);
      }
      .custom-validate-input {
        .input-label {
          background-color: #ffffff;
        }
      }

      .input-field {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 85%;
        margin: auto;

        .input-percent {
          display: flex;
          align-items: center;
          justify-content: left;

          .percent-icon {
            font-weight: bold;
            font-size: 18px;
          }

          .number-style {
            width: 40px;
            /*margin: 15px 0;*/
            margin-left: 10px;
          }
        }

        .in-valid {
          position: absolute;
          bottom: 0;
          color: $danger;
          font-size: 11px;
          margin: auto;
          text-align: center;
        }
      }

      .view-box {
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: auto;

        .general-fields {
          margin-bottom: 20px;
        }

        .custom-profile-image-input {
          .profile-image-label {
            height: 110px;
            width: 110px;
          }
        }

        .date-picker-item {
          border: 0 !important;
          border-radius: 0.4rem;
          position: relative;

          .date-picker-label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 5px;
            padding: 0 5px;
            background-color: #ffffff;
            z-index: 1;
          }

          .vpd-input-group {
            width: 20px !important;
            position: absolute;
            top: 6px;
            left: 2px;

            .vpd-icon-btn {
              opacity: 1 !important;
              height: 25px !important;
              width: 20px !important;
              background-color: inherit !important;

              svg {
                fill: #000000;
                overflow: visible !important;
              }
            }

            .form-control {
              border: 0;
              width: 100%;
              display: none;
              line-height: 35px;
            }
          }
        }

        .custom-input:last-child {
          margin-bottom: 0;
        }

        .input-field-item {
          position: relative;
          border: 1px solid #cdcdcd;
          border-radius: 0.4rem;
          padding: 5px 10px;
          margin: 15px 0;
          display: flex;
          justify-content: space-between;
          min-height: 35px;
          flex-wrap: wrap;

          &.invalid {
            border-color: #b00000;

            .input-field-label {
              color: #b00000;
            }
          }

          .input-field-label {
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 8px;
            background-color: #ffffff;
            padding: 0 5px;
          }

          .radio-item {
            display: flex;
            align-items: center;
            width: max-content;
            direction: rtl;

            label {
              direction: ltr;
              margin-left: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &.character-input {
            justify-content: space-between;

            .radio-item {
              justify-content: flex-end;
              width: 33%;
            }
          }
        }

        div.edit-user-field {
          position: relative;

          span.label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 7px;
            z-index: 10000;
            background: #ffffff;
            padding: 0 5px;
          }

          .useral-custom-element-select {
            margin: 15px 0;

            .selected {
              height: 35px;
              line-height: 35px;
              font-size: 13px;
            }

            .items {
              line-height: 35px;

              div {
                height: 35px;
                font-size: 13px;
              }
            }
          }
        }

        .select-character-section {
          margin-top: 30px;
          padding: 30px 5px 0;
          border: 1px solid #cdcdcd;
          border-radius: 0.5rem;
          position: relative;

          .title {
            position: absolute;
            top: -11px;
            left: 5px;
            padding: 0 5px;
            backdrop-filter: sepia(1);
          }
        }

        .insert-attribute-btn {
          font-size: 18px;
          position: absolute;
          right: 3px;
          top: -10px;
          bottom: 0;
          margin: auto;
          height: fit-content;
          padding: 6px;
          border-radius: .5rem;
          border: 1px solid #cecece;
          background: #cecece54;
          cursor: pointer;
          z-index: 200;
          transition: all .3s ease;

          &:hover {
            box-shadow: 0 0 10px #cecece;
          }
        }

        .vs-divider {
          margin-bottom: 20px;

          .vs-divider-border.after {
            background-color: unset;
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
